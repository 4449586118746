

@media screen and (max-width:360px){
    .filter{
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
   .left{
    width: 100% !important;
    margin-top: 100px;
   }
   .input{
    width: 100% !important;
   }
   .but{
    margin-bottom: 5px !important;
   }
   .page{
    width:100vw
   }
   .showing{
    margin-top: 20px;
   }
  
} 
