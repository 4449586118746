.cart-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.cart-items {
    display: flex;
    gap: 22%
}

#coupon-cotainer {
    display: flex;
    justify-content: space-between;
    padding: 1%;
    width: 95%;
    margin: auto;
    background-color: white;
    /* text-align: center; */
    align-items: center;
    border-radius: 3%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.cart-header {
    font-family: Poppins-Bold;
    font-size: 13px;
    color: #555;
    text-transform: uppercase;
    line-height: 1.6;
    padding-top: 15px;
    padding-bottom: 15px;
}
#card-product-image {
    width: 7rem;
    height: 7rem;
}


#cart-prod-name {
    font-family: Poppins-Regular;
    color: #555;
    line-height: 1.6;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.cart-button {
    border: 1px solid #e6e6e6;
    background-color: white;
    color: #555;
    font-size: 16px;
    padding: 6%;
    width: 30%;
}

.cart-button:hover {
    background-color: rgba(103, 117, 214, 0.8);
    color: white;
    font-size: 16px;
}

.carttot-head {
    font-family: Poppins-Bold;
    font-size: 20px;
    line-height: 1.3;
    text-transform: uppercase;
    color: #333;
}

.cart-subTotal {
    font-family: Poppins-Medium;
    font-size: 17px;
    line-height: 1.466667;
    font-weight: 500;
    color: #333;
}

.shipping-para {
    font-family: Poppins-Regular;
    font-size: 15px;
    line-height: 1.6923;
    color: #888;
    width: 75%
}

.cart-total-button {
    border: none;
    color: black;
    padding: 4%;
    border-radius: 9999px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #e6e6e6;
    font-family: Poppins-Medium;
    font-size: 16px;
    line-height: 1.466667;
    text-transform: uppercase;
}

.cart-total-button:hover {
    background-color: rgba(103, 117, 214, 0.8);
    color: white;
    font-size: 16px;
}

.payment-button {
    border: none;
    margin-top: 3%;
    color: white;
    background-color: black;
    padding: 3%;
    border-radius: 9999px;
    padding-left: 12%;
    padding-right: 12%;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #e6e6e6;
    font-family: Poppins-Medium;
    font-size: 16px;
    line-height: 1.466667;
    text-transform: uppercase;
}

.payment-button:hover {
    background-color: rgba(103, 117, 214, 0.8);
    color: white;
    font-size: 16px;
}

.coupon-input {
    border: 1px solid #e6e6e6;
    border-radius: 22px;
    font-family: Poppins-Regular;
    font-size: 18px;
    line-height: 1.466667;
    color: #333;
    margin-right: 10px;
    padding-right: 20px;
    padding-left: 10px;
    padding: 2%;
}

.coubon-button {
    border: none;
    margin-top: 3%;
    color: black;
    padding: 3%;
    border-radius: 9999px;
    padding-left: 12%;
    padding-right: 12%;
    margin-left: 8%;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #e6e6e6;
    font-family: Poppins-Medium;
    font-size: 14px;
    line-height: 1.466667;
    text-transform: uppercase;
}

.ui.input {
    width: 100%;
}

.cart-pay-container {
    border: 1px solid #e6e6e6;
    width: 83%;
    margin-left: auto;
    padding: 5%;
}

@media(max-width:1022px) {
    .cart-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .cart-pay-container {
        border: 1px solid #e6e6e6;
        width: 100%;
        margin: auto;
        padding: 5%;
        margin-top: 3%;
    }
}

.cart-total-prize {
    vertical-align: middle;
}


@media(max-width:768px) {
    .cart-total-prize {
        vertical-align: middle;
    }
}


.add-address-button {
    border: none;
    margin-top: 3%;
    color: black;
    padding: 1%;
    margin-left: 8%;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #e6e6e6;
    font-family: Poppins-Medium;
    font-size: 16px;
    line-height: 1.466667;
    text-transform: uppercase;
}

.add-address-button:hover {
    background-color: rgba(103, 117, 214, 0.8);
    color: white;
    font-size: 16px;
}

.delete-cart-item {
    font-size: 19px;
    padding-top: 58%;
}

.cart-page-container {
    margin-top: 3%;
    margin-bottom: 3%;
}

@media(max-width:752px) {
    .cart-page-container {
        margin-top: 16%;
        margin-bottom: 3%;
    }

    .delete-cart-item {
        padding-top: 0%;
    }
}

#wishlist-table-container {
    padding: 5%;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 5%;
}

#cart-product-image{
    width: 100%;
    height: 5rem;
}

#wishlist-product-image{
    width: 100%;
    height: 5rem;
}

#card-product-image {
    width: 7rem;
    height: 7rem;
}



@media(max-width:765px) {
    .cart-total-button {
        width: 90%;
    }

    #cart-table-container {
        text-align: center;
    }

    .payment-button {
        width: 90%;
    }

    #wishlist-table-container {
        padding: 5%;
        text-align: center;
        padding-left: 10%;
        padding-right: 10%;
        margin-top: 13%;
    }


    #cart-name-image {
        display: flex;
        justify-content: center;
    }


    #cart-prod-name {
        justify-content: center;
    }

    #cart-product-image{
        width: 50%;
        height: 5rem;
    }
    
    #wishlist-product-image{
        width: 50%;
        height: 5rem;
    }
}