.parallax2 {
    height: 500px; /* Adjust as needed */
    background-attachment: fixed;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 30px;
  }
  @media (max-width:360px){
    .parallax2 {
      height: 500px; /* Adjust as needed */
   background-attachment: scroll;
   background-position: top;
   background-size: cover;
   margin-bottom: 0px;
    }
    .content{
      left: 0 !important;
     
    }
    .p{
      font-size: 18px !important;
      width: 200px;
    }
  }