.second-title {
    font-family: Poppins-Bold;
    font-size: 36px;
    line-height: 1.1;
    text-transform: uppercase;
    font-weight: 800;
    margin-top: 3%;
    margin-bottom: 2%;
}

.secondary-category-contents {
    display: grid;
    grid-template-columns: 63% 2fr;
    overflow: hidden;
}

.product-button-container {
    text-align: end;
    width: 100%;
}

@media(max-width:1033px) {
    .secondary-category-contents {
        display: grid;
        grid-template-columns: repeat(1, 100%);
        overflow: hidden;

    }

    .product-button-container {
        text-align: center;
        width: 100%;
    }
}





.secondary-item {
    font-family: Poppins-Regular;
    cursor: pointer;
    color: #888;
    font-size: 17px;
    font-weight: bold;
    border: none;
    background-color: white;
}

#select-secondary {
    font-family: Poppins-Regular;
    border-bottom: 1px solid black;
    cursor: pointer;
    /* height: 90%; */
    color: black;
}

.secondary-item:hover {
    font-family: Poppins-Regular;
    border-bottom: 1px solid black;
    cursor: pointer;
    /* height: 90%; */
    color: black;
}

.secondary-category-container {
    display: flex;
    width: 100%;
}



.product-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3%;
    padding: 2%;
}

@media(max-width:1000px) {
    .product-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding-left: 3%;
        padding-right: 3%;
        padding-left: 4%;
        padding-right: 4%;
        gap: 3%;
        padding: 2%;
    }
}

@media(max-width:800px) {
    .product-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding-left: 4%;
        padding-right: 4%;
        gap: 3%;
        padding: 2%;
    }
}

@media(max-width:600px) {
    .product-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        padding-left: 4%;
        padding-right: 4%;
        gap: 2%;
        padding: 2%;
        margin-left: 6%;
    }
}

.product-name {
    font-size: 14px;
    font-weight: bold;
}

.product-heart:hover {
    color: rgba(103, 117, 214, 0.8);
}

.product-name:hover {
    color: rgba(103, 117, 214, 0.8);
}

.image-container {
    overflow: hidden;
    position: relative;
}

.zoomable-image {
    height: auto;
    transition: transform 0.6s ease-out;
}

.image-container:hover .zoomable-image {
    transform: scale(1.1);
}

.overlay {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    transform: translateY(100%);

}

.image-container:hover .overlay {
    transform: translateY(0);
}

.product-button {
    font-size: 15px;
    background-color: white;
    border: 1px solid #e6e6e6;
    border-radius: 9999px;
    padding: 6%;
    min-width: 70%;
}

.product-button:hover {
    background-color: black;
    color: white;
    border: none;
}


.search-box {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: transform 5s ease-out;
}

.search-box.open {
    height: 80%;
    /* Adjust the height as needed */
    transition: transform 5s ease-out;
    margin-bottom: 3%;
}

.search-box Input {
    width: 80%;
    padding: 10px;
}


.filter-box {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: transform 5s ease-out;
}

.filter-box.open {
    height: 80%;
    /* Adjust the height as needed */
    transition: transform 5s ease-out;
}

.filter-box div {
    width: 80%;
    padding: 10px;
    background-color: #888;
}

#custom-button {
    width: 40%;
    background-color: white;
    border: 1px solid #e6e6e6;
}

/* #custom-button:hover {
    background-color: red;
} */

#custom-button-icon {
    margin-right: 10%;
    font-size: 16px;
}

@media(max-width:750px) {
    .second-title {
        margin-top: 10%;
        margin-top: 15%;
        margin-bottom: 5%;
        font-size: 20px;
    }

    #custom-button-icon {
        font-size: 16px;
    }

    #custom-button {
        width: 97%;
        background-color: white;
        border: 1px solid #e6e6e6;
    }
}

/* .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    min-width: 90%;
} */



.productCarousel.carousel-root {
    display: flex;
    flex-direction: row-reverse;
    gap: 15px;
}

.productCarousel.carousel-root .carousel {
    width: auto;
}

.productCarousel.carousel-root .carousel.carousel-slider {
    width: 100%;
}

.productCarousel.carousel-root .carousel.carousel-slider .slider-wrapper {
    border-radius: 10px;
}

.productCarousel.carousel-root .carousel .thumbs-wrapper {
    margin: 0;
}

.productCarousel.carousel-root .carousel .thumb {
    height: 60px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
}

.productCarousel.carousel-root .carousel .thumb img {
    width: 100%;
    height: 100%;
    object-position: center;
}

.productCarousel.carousel-root .carousel .thumb:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0;
}

.productCarousel.carousel-root .carousel .thumb.selected,
.productCarousel.carousel-root .carousel .thumb:hover {
    border: 0;
}

.productCarousel.carousel-root .carousel .thumb:hover:after,
.productCarousel.carousel-root .carousel .thumb.selected:after {
    opacity: 0.2;
}

.productCarousel .control-arrow {
    display: none;
}

@media screen and (max-width: 767px) {
    .productCarousel.carousel-root {
        flex-direction: column;
    }

    .productCarousel.carousel-root .carousel .thumb {
        border: 0;
        padding: 0;
        margin: 0;
    }
}

@media screen and (min-width: 768px) {
    .productCarousel.carousel-root .carousel .thumbs {
        transform: none !important;
        flex-direction: column;
        display: flex;
        gap: 10px;
    }

    .productCarousel.carousel-root .carousel .thumb {
        border: 0;
        padding: 0;
        margin: 0;
    }
}

.dialog-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5%;
    padding: 3% 3% 3% 3%;
}

@media(max-width:800px) {
    .dialog-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 5%;
        padding: 3% 3% 3% 3%;
    }
}

.single-prod-name {
    font-family: Poppins-Regular;
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
}

.single-prod-prize {
    font-family: Poppins-SemiBold;
    font-size: 18px;
    line-height: 1.388888;
    font-weight: 800;
}

.single-prod-description {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666;
    padding-top: 4%;
    margin: 0px;
}

.dialog-button {
    border: 1px solid #e6e6e6;
    background-color: white;
    color: #555;
    font-size: 16px;
    padding: 5%;
    width: 33%;
}

.dialog-button:hover {
    background-color: rgba(103, 117, 214, 0.8);
    color: white;
    font-size: 16px;
}

.auto-button {
    background-color: #717fe0;
    border: none;
    color: white;
    width: 100%;
    padding: 9%;
    border-radius: 9999px;
    font-size: 16px;
    padding-left: 12%;
    padding-right: 12%;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}


.fXsRmT {
    background: none;
    border: 4px solid #687af3;
    font-weight: 800;
    font-size: 30px;
}

.scroll-contents {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 0;
    padding-bottom: 11px;
    padding-top: 2px;
    overflow-x: auto;
    gap: 4%;
    white-space: nowrap;
    scroll-snap-type: x proximity;
}

.scroll-contents::-webkit-scrollbar {
    width: 2px;
    height: 10%;
}

.scroll-contents::-webkit-scrollbar-thumb {
    background-color: none;
    background: none;
    border: none;
    border-radius: 0px;
}



.product-master {
    margin-top: 5%;
    margin-bottom: 5%;
}

@media(max-width:968px) {
    .product-master {
        margin-top: 15%;
        margin-bottom: 5%;
    }
}



@media(max-width:468px) {
    .product-master {
        margin-top: 27%;
        margin-bottom: 18%;
    }
}

.secondary-arrow {
    margin-top: 1%;
    margin-right: 2%;
    font-size: 17px;
    cursor: pointer;
}

.secondary-right-arrow {
    margin-top: 1%;
    margin-left: 2%;
    font-size: 17px;
    cursor: pointer;
}

@media(max-width:768px) {
    .product-master {
        margin-top: 21%;
        margin-bottom: 14%;
    }

    .secondary-arrow {
        margin-top: 0%;
        margin-right: 2%;
        font-size: 27px;
        cursor: pointer;
    }

    .secondary-right-arrow {
        margin-top: 0%;
        margin-left: 2%;
        font-size: 27px;
        cursor: pointer;
    }
}

.carouusel-image {
    position: sticky;
    top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1360px;
    font-size: 20px;
    color: white;
}

.carl-img {
    height: 24rem;
}
