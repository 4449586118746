.parallax {
    height: 700px; /* Adjust as needed */
    background-attachment: fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }

 
    @media (max-width:360px){
      .parallax {
        height: 500px; /* Adjust as needed */
     background-attachment: scroll;
     background-position: top;
     background-size: cover;
     margin-bottom: 0px;
      }
      .content{
        left: 0 !important;
       
      }
      .p{
        font-size: 18px !important;
        width: 200px;
      }
    }
  