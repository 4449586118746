.order-container{
    margin-top: 7%;
    margin-bottom: 4%;
    margin-left: 9%;
    margin-right: 9%;
}

.order-details{
    display: flex;
    font-size: 17px;
    border-bottom:1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 1%;
    
}

.order-item-container{
    display: flex;
    flex-direction: column;
}

.order-item{
    display: flex;
    margin-top: 2%;
    border-bottom:1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 1%;
}


.oreder-address-container{
    display: flex;
    margin-top: 2%;
}

.customer-address{
    margin-left: 30%;
    margin-bottom: 7%;
}

.oreder-pay-title{
    font-size: 17px;
}


@media(max-width:500px){
    .order-container{
        margin-top: 20%;
    }
}

@media(max-width:900px){
    .order-container{
        margin-top: 14%;
    }
}