.navbar-container {
    display: flex;
    width: 100%;
    padding: 2%;
    gap:8%;
    position: fixed;
    z-index: 99;
    top:0;
}
.navbar-container.scrolled {
    background-color: white; /* your desired background color when scrolled */
  }


.navbar-contents-container {
    display: flex;
    width: 41%;
    justify-content: space-around;
    font-weight: 600;
    font-size: 14px;
}

.navbar-content:hover {
    cursor: pointer;
    color: #717fe0;
}

.navbar-icons {
    display: flex;
    width: 10%;
    margin-left: auto;
    margin-right: 5%;
    /* margin-left: auto; */
    justify-content: space-around;
    font-size: 27px;
}

.navbar-icon:hover {
    cursor: pointer;
    color: #717fe0;
}

.navbar-logo {
    font-size: 19px;
    width: 24%;
    text-align: center;
}

@media(max-width:980px){
    .navbar-contents-container {
        display: none;
    }
    .nav-input{
        display: none;
    }
}

@media(min-width:980px){
    .mob-menu{
        display: none;
    }
}

@media(max-width:360px){
    .navbar-container {
        display: flex;
        width: 360px;
        padding: 5%;
        gap:4%;
        position: fixed;
        z-index: 99;
        top:0;
    }
}
