.footer-categories {
    display: grid;
    gap: 10%;
    margin-bottom: 3%;
    margin-left: 2%;

}

.footer {
    background-color: black;
    color: white;
    padding-top: 3%;
    text-align: left;
    padding-left: 5%;
    padding-right: 3%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.footer-contents {
    font-size: 16px;
    margin-bottom: 3%;
}

#footer-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 11%;
}
#footer-container1{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 11%;
}

@media(max-width:945px) {
    #footer-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20%;
    }
    #footer-container1{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20%;
    }

    /* .footer {
        background-color: black;
        color: white;
        height: 59vh;
        text-align: center;
    } */
}

.footer-input {
    border: none;
    border-bottom: 2px solid #999;
    outline: none;
    background-color: transparent;
    color: white;
    margin-bottom: 7%;
    padding-top: 2%;
    font-size: 16px;
}

.footer-input::placeholder {
    color: #999;
    /* Set the color of the placeholder text */
}

.footer-input:focus {
    border-color: #717fe0;
}

.footer-button {
    background-color: #717fe0;
    border: none;
    color: white;
    padding: 5%;
    border-radius: 9999px;
    font-size: 16px;
    padding-left: 14%;
    padding-right: 14%;
}

@media(max-width:430px) {
    #footer-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 13%;
    }
    #footer-container1 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 13%;
    }
    

    /* .footer {
        background-color: black;
        color: white;
        height: 113vh;
        text-align: center;
    } */

    .footer-categories {
        display: grid;
        gap: 4%;
        margin-bottom: 0;
    }

}

#add-enq {
    width: 40%;
    margin: auto;
}

@media(max-width:800px) {
    #add-enq {
        width: 100%;
        margin: auto;
    }
 
    }
