.image-container {
  width: 100%;
  height: 85%;
}

/* .image-container { */


/* Previous arrow */
.carousel-arrow-prev {
  position: absolute;
  bottom: 45%;
  left: 5%;
  height: 7%;
  font-size: 48px;
  color: black;
  background-color: transparent;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s;
}

.carousel-arrow-prev:hover {

  color: #687af3;
}

.carosel-image {
  height: 25vh;
}

.carousel-arrow-prev i {
  font-size: 14px;
  /* You can adjust the font size to match text-sm or md:text-lg */
}

/* Next arrow */
.carousel-arrow-next {
  position: absolute;
  right: 5%;
  bottom: 45%;
  height: 7%;
  opacity: 0.7;
  color: black;
  font-size: 32px;
  background-color: transparent;
  z-index: 10;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  /* transform: rotate(180deg); */
}

.carousel-arrow-next:hover {
  color: #687af3;
}

.carousel-arrow-next i {
  font-size: 14px;
  /* You can adjust the font size to match text-sm or md:text-lg */
}

/* MyComponent.css */
.container {
  position: absolute;
  margin-left: 5%;
  top: 47%;
  left: 5%;
  width: 60%;
  text-align: left;
}

@media (min-width: 768px) {
  .container {
    left: 6%;
    color: black
  }
}

.text-container {
  margin-top: 15%;
  width: 100%;
  opacity: 0.5;
  border-radius: 10px;
  padding: 3%;
  padding-bottom: 1%;
  justify-content: center;
  background-color: #202020;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: white;
}

@media (min-width: 768px) {
  .text-container {
    gap: 5px;
  }

  .title {
    font-size: 2rem;
  }

  .subTitle {
    font-size: 1.125rem;
  }
}

.subTitle {
  font-size: 1.5rem;
}

.title {
  font-size: 1.2rem;
}

/* YourComponent.css */

/* Absolute black background with animation */
.absolute-bg {
  position: absolute;
  left: 0;
  width: 100%;
  height: 0;
  transition: all 0.4s ease;
  background-color: black;
  opacity: 1;
  top: 50%;
  transform: translateY(-50%);
}

/* Absolute right-aligned container */
.absolute-right {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 2.5rem;
  /* Adjust width as needed */
  height: 2.5rem;
  /* Adjust height as needed */
  transition: transform 0.3s ease;
  transform: translateX(100%);
}

/* SVG icon styles */
.svg-icon {
  width: 5px;
  height: 5px;
  stroke: currentColor;
}

/* Button text */
.button-text {
  position: relative;
  font-size: 1.5rem;
}

/* Hover effect */
.group:hover .absolute-bg {
  height: 100%;
  top: 0;
}

.group:hover .absolute-right {
  transform: translateX(0);
}


.button-container {
  position: relative;
  width: 10rem;
  height: 3rem;
  padding-left: 1.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.8rem;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  font-size: 0.5rem;
  font-weight: 500;
  color: white;
  background-color: #717fe0;
  border-radius: 23px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.button-container:hover {
  color: white;
  background-color: black;
}


.svg-icon {
  width: 1.25rem;
  /* Adjust width as needed */
  height: 1.25rem;
  /* Adjust height as needed */
  stroke: currentColor;
  fill: none;
  stroke-width: 2;
}

.button-text {
  margin-left: 0.5rem;
  font-size: 1.15rem;
}


/* CSS for the button */
.button {
  position: relative;
  display: inline-block;
  padding: 0.5rem 1rem;
  /* Adjust padding as needed */
  border: 2px solid black;
  background-color: transparent;
  color: black;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.button:hover {
  background-color: black;
  color: white;
}

@media (max-width: 800px) {
  .container {
    position: absolute;
    margin-left: 5%;
    top: 27%;
    left: 5%;
    width: 60%;
    text-align: left;
  }
}


@media (max-width: 520px) {
  .carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden;
  }

  .image-container {
    width: 28rem;
    height: 65%;
  }

  .text-container {
    gap: 5px;
  }

  .title {
    font-size: 1rem;
  }

  .subTitle {
    font-size: 1rem;
  }
}

.image-size {
  height: 85vh;
}

@media (max-width: 800px) {
  .image-size {
    height: 30vh;
  }

  .image-container {
    width: 100%;
    height: 65%;
  }

  .carousel-arrow-prev {
    position: absolute;
    bottom: 45%;
    left: 5%;
    height: 7%;
    font-size: 30px;
    color: black;
    background-color: transparent;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.3s;
  }

  .carousel-arrow-next {
    position: absolute;
    right: 5%;
    bottom: 45%;
    height: 7%;
    opacity: 0.7;
    color: black;
    font-size: 20px;
    background-color: transparent;
    z-index: 10;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
    /* transform: rotate(180deg); */
  }
}