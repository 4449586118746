.master-container {
    height: 20%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2%;
    height: 20%;
    place-items: center;
}

.master-container-scroll {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 0;
    padding-bottom: 11px;
    padding-top: 2px;
    overflow-x: auto;
    gap: 4%;
    white-space: nowrap;
    overflow: hidden;
    scroll-snap-type: x proximity;
    /* height: 20%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2%;
    height: 20%;
    place-items: center; */
}

@media(max-width:1110px) {
    .master-container {
        height: 20%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2%;
        height: 20%;
        place-items: center;
    }
}

@media(max-width:737px) {
    .master-container {
        height: 20%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2%;
        height: 20%;
        place-items: center;
    }
}

.shop-button {
    display: none;
}

.master-category-name {
    position: absolute;
    z-index: 9;
    margin: 3% 0 0 3%;
    color: black;
    top: 15px;
    left: 15px;
    font-size: 1.8rem;
    font-weight: bold;
    font-family: Poppins-Bold;
}
.master-category-name1 {
    position: absolute;
    z-index: 9;
    margin: 0% 0 0 0%;
    color: black;
    top: 30px;
    left: 30px;
    font-size: 1.8rem;
    font-weight: bold;
    width: 100%;
    font-family: Poppins-Bold;
}

.master-category-name1:hover {
    color: white;
}


.mastercat-card {
    position: relative;
    border: 1px solid #e6e6e6;
    height: 18rem;
}


.image-overlay {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    
    overflow: hidden;
    width: 100%;
    height: 0;
    
}

.mastercat-card:hover .image-overlay {
    bottom: 0;
    opacity: 0.5;
    height: 100%;
   
}

.mastercat-card:hover .master-category-name {
    color: white;
    z-index: 10;
}

.mastercat-card:hover .shop-button {
    position: absolute;
    bottom: 0;
    display: inline;
    color: white;
    z-index: 10;
    font-size: 1.3rem;
    font-weight: bold;
    font-family: Poppins-Bold;
}